import React, { useState, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import AdminTextInput from '../../../components/admin/admin-text-input.js';
import { addTypicalLayout, getById } from '../../../api/typicallayoutsapi.js';
import MediaUploader from '../../../components/media/MediaUploader.js';
import { TypicalLayoutItems } from './typicalLayoutItems.js';
//import { listCategories } from '../../../api/categoriesapi.js';
import { AddEditDescription } from './addEditDescriptionModal';
import AdminError from '../../../components/admin/admin-error';
import { updateTitle } from '../../../helpers/titleHelper';

export function AddEditTypicalLayout() {
    const { districtId } = useParams();
    const { typicalid } = useParams();
    const [typicalLayout, setTypicalLayout] = useState(TypicalLayoutItems);
    const [mediaItems, setMediaItems] = useState([]);
    const [Category, setCategory] = useState([]);
    const navigate = useNavigate();
    const [showAddDescriptionModal, setShowAddDescriptionModal] = useState(false);

    const defaultDescription = { id: 0, description: '', order: 0, icon: '', title: '' };
    const [descriptionModel, setDescriptionModel] = useState(defaultDescription);
    const [descriptions, setDescriptions] = useState([]);
    const [lastDescriptionId, setLastDescriptionId] = useState(0);
    const [error, setError] = useState('');
    const [validation, setValidation] = useState({
        title: true,
        category: true,
        products: true
    });

    let gettypicalLayoutitems = localStorage.getItem('editTypicalLayout');
    let TypicalDetailList = "";
    if (gettypicalLayoutitems != undefined) {
        TypicalDetailList = JSON.parse(gettypicalLayoutitems);
    }

    const sumOfQuantities = (typicalLayout?.products || []).reduce((accumulator, product) => {
        if (!product.isDeleted) {
            accumulator += product.quantity || 0;
        }
        return accumulator;
    }, 0);

   
    useEffect(() => {
        setCategory([]);
        if (TypicalDetailList?.products?.length > 0) {
            setTypicalLayout(TypicalDetailList);
            if (TypicalDetailList.mediaItems !== null && TypicalDetailList.mediaItems.length > 0) {
                let getMediaItems = []; 
                TypicalDetailList.mediaItems.forEach((item) => {
                    if (item?.mediaItem != null) {
                        item.previewsrc = item.mediaItem.previewsrc;
                    }
                    getMediaItems.push(item);
                });
                setMediaItems(getMediaItems);
            }
            if (TypicalDetailList.description != undefined && TypicalDetailList.description != null && TypicalDetailList.description != '') {
                let getdescriptionlist = JSON.parse(TypicalDetailList.description);
                let maxId = 0;
                getdescriptionlist.forEach(description => {
                    if (description.id > maxId) {
                        maxId = description.id;
                    }
                });
                setLastDescriptionId(maxId);
                setDescriptions(getdescriptionlist);
            }
        }
    }, []);


    useEffect(() => {
        const updatedTypicalLayout = { ...typicalLayout, description: JSON.stringify(descriptions), districtId: districtId, title: typicalLayout.title, categoryId: typicalLayout.categoryId, mediaItems: typicalLayout.mediaItems, IsEditCompleteCart: typicalLayout.IsEditCompleteCart };
        localStorage.setItem('editTypicalLayout', JSON.stringify(updatedTypicalLayout));
        localStorage.removeItem("typicallayout");
    }, [typicalLayout, descriptions]);

    const handleTypicalLayoutSave = () => {

        let isValid = true;
        const validate = {
            title: true,
            category: true,
            products: true
        };
        setValidation({ ...validation, category: typicalLayout.products.length !== "0" });

        if (typicalLayout.categoryId <= 0) {
            validate.category = false;
            isValid = false;
        }
        if (!typicalLayout.title || typicalLayout.title.trim() === '') {
            validate.title = false;
            isValid = false;
        }
        if (typicalLayout.products.length <= 0) {
            validate.products = false;
            isValid = false;
        }
        setValidation({ ...validation, ...validate });
        if (!isValid) {
            return;
        }

        typicalLayout.districtId = districtId;
        typicalLayout.description = JSON.stringify(descriptions);
        addTypicalLayout(typicalLayout).then(response => {
            if (response.data.success === true) {
                localStorage.removeItem("editTypicalLayout");
                localStorage.removeItem("typicallayout");
                navigate('/admin/districts/edit/' + districtId + '/layouts')
            }
        });
    };

    useEffect(() =>
    {
        updateTitle('Admin', 'Add New Typical Layout');
        if (typeof localStorage.getItem('editTypicalLayout') != 'string') {
            localStorage.setItem('editTypicalLayout', '{}');
        }
        if (typicalid != undefined && TypicalDetailList?.IsEditCompleteCart != true) {
            getById(typicalid).then(response => {
                if (response.data.success === true) {
                    let productResponse = response.data.data;
                    setTypicalLayout(response.data.data);
                    updateTitle('Admin', 'Edit Typical Layout - ' + response.data.data.title);
                    if (productResponse.mediaItems !== null && productResponse.mediaItems.length > 0) {
                        let getMediaItems = [];
                        productResponse.mediaItems.forEach((item) => {
                                item.mediaItem.mediaItemId = item.mediaItemId;
                            getMediaItems.push(item.mediaItem);
                        });
                        setMediaItems(getMediaItems);
                    }

                    if (productResponse.description != undefined && productResponse.description != null && productResponse.description != '') {
                        let getdescriptionlist = JSON.parse(productResponse.description);
                        let maxId = 0;
                        getdescriptionlist.forEach(description => {
                            if (description.id > maxId) {
                                maxId = description.id;
                            }
                        });

                        setLastDescriptionId(maxId);
                        setDescriptions(getdescriptionlist);
                    }

                }
                else {
                    setTypicalLayout(TypicalLayoutItems);
                }
            });

        }
        else if (TypicalDetailList != "" && TypicalDetailList != undefined && TypicalDetailList.IsEditCompleteCart == true) {
            TypicalDetailList.IsEditCompleteCart = false;
            localStorage.setItem('editTypicalLayout', JSON.stringify(TypicalDetailList));
        }
    }, [districtId]);
    const handleMediaUpdates = (mediaItems) => {

        const updatedMediaItems = mediaItems.map((item, index) => ({
            typicalLayoutId: typicalLayout.id,
            mediaItemId: item.id ,
            order: index + 1 ,
            isDeleted: false,
            mediaItem: {
                ...item,
            },
        }));


        setTypicalLayout({
            ...typicalLayout,
            mediaItems: updatedMediaItems,
        });
    };
     
    useEffect(() => {
    }, []);


    const handleDescription = (descriptionData) => {
        if (descriptionData != undefined && descriptionData != null) {
            if (descriptionData.id > 0) {
                // Update existing description
                const updatedDescriptions = descriptions.map(desc => {
                    if (desc.id === descriptionData.id) {
                        return {
                            ...desc,
                            description: descriptionData.description,
                            order: descriptionData.order,
                            icon: descriptionData.icon,
                            title: descriptionData.title
                        };
                    }
                    return desc;
                });
                setDescriptions(updatedDescriptions);
            } else {
                // Add new description
                const uniqueId = lastDescriptionId + 1;
                const newDescription = {
                    id: uniqueId,
                    description: descriptionData.description,
                    order: descriptionData.order,
                    icon: descriptionData.icon,
                    title: descriptionData.title
                };
                const updatedDescriptions = [...descriptions, newDescription];
                setDescriptions(updatedDescriptions);
                setLastDescriptionId(uniqueId);
            }
        }
    };

    const handleAddEmailDistrict = () => {
        setDescriptionModel(defaultDescription);
        setShowAddDescriptionModal(true);
    }

    const handleEditDescription = (des) => {
        setDescriptionModel({ id: des?.id, description: des?.description, order: des?.order, icon: des?.icon, title: des?.title });
        setShowAddDescriptionModal(true);
    };

    const handleDeleteDescription = (index) => {
        const updatedDescriptions = [...descriptions];
        updatedDescriptions.splice(index, 1);
        setDescriptions(updatedDescriptions);
    };

    return (
        <>
            <AddEditDescription
                show={showAddDescriptionModal}
                onClose={() => setShowAddDescriptionModal(false)}
                handleAddDescription={handleDescription}
                modelDescription={descriptionModel}
            />

            <div className="admin-page-wrap">
                <div className="admin-top-bar-wrap">
                    <div className="admin-top-bar-left">
                        <Link
                            className="admin-top-bar-back"
                            to={`/admin/districts/edit/${districtId}/layouts`}
                            onClick={() => {
                                localStorage.removeItem("editTypicalLayout");
                                localStorage.removeItem("typicallayout");
                            }}
                        >
                            Back
                        </Link>
                    </div>
                </div>
                <div className="admin-page-content">

                    <h1 className="admin-page-heading">Add / Edit Typical Layout</h1>

                    <AdminError
                        message={error}
                        onClose={() => {
                            setError('');
                        }}
                    ></AdminError>

                    <div className="admin-section-row">
                        <div className='admin-section-cell wide'>
                            <div
                                className={
                                    'admin-section-cell wide' +
                                    (validation.category === false
                                        ? ' input-validation-error'
                                        : '')
                                }
                            >
                                <label className="admin-default-label" htmlFor="category">
                                    Select Category*
                                </label>
                                <select className="admin-default-select"
                                    id="category"
                                    value={typicalLayout.categoryId}
                                    onChange={(e) => {
                                        setTypicalLayout({
                                            ...typicalLayout,
                                            categoryId: e.target.value
                                        });
                                        setValidation({ ...validation, category: e.target.value !== "0" });
                                    }}
                                >
                                    <option value={0}>Select Category</option>
                                    {Category.map((category) => (
                                        <option key={category.id} value={category.id}>
                                            {category.name}
                                        </option>
                                    ))}

                                </select>
                            </div>
                        </div>
                    </div>


                    <div className="admin-section">
                        <div className="admin-section-row">
                            <div className="admin-section-cell wide">
                                <AdminTextInput
                                    label="Title*"
                                    id="Title"
                                    value={typicalLayout.title ?? ''}
                                    onChange={(value) => {
                                        setTypicalLayout({
                                            ...typicalLayout, title: value
                                        });
                                        setValidation({ ...validation, title: value != '' });
                                    }}
                                    validated={validation.title}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="admin-section-row">
                        <div className="admin-section-title-bar">
                            <p className="admin-section-headline">Descriptions</p>
                            <div className="admin-section-title-action-wrap">
                                <button className="admin-button" onClick={() => handleAddEmailDistrict(true)}>
                                    <span className="admin-button-text">Add Description</span>
                                    <span className="material-symbols-rounded" aria-hidden="true">add</span>
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="admin-section-row">
                        <div className="descriptions-box-row">
                            {descriptions && descriptions.map((desc, index) => (
                                <React.Fragment key={index}>
                                    <div className="admin-tall-list-item">
                                        {/*<span*/}
                                        {/*    dangerouslySetInnerHTML={{*/}
                                        {/*        __html: desc.description,*/}
                                        {/*    }}*/}
                                        {/*></span>*/}
                                        <span className="admin-title-symbol admin-skill-tile-name">
                                            {/*<span className="material-symbols-rounded desc-icon">{desc?.icon}</span>*/}
                                            {desc.title}
                                        </span>
                                        <span className="admin-skill-tile-action-wrap">
                                            <button
                                                className="admin-tall-list-edit"
                                                onClick={() => handleEditDescription(desc)}
                                            >
                                                <span className="material-symbols-rounded">edit</span>
                                            </button>
                                            <button
                                                className="admin-tall-list-edit"
                                                onClick={() => handleDeleteDescription(index)}
                                            >
                                                <span className="material-symbols-rounded">delete</span>
                                            </button>
                                        </span>

                                    </div>
                                </React.Fragment>
                            ))}
                        </div>
                    </div> 


                    <div className="admin-section-row">
                        <div className="admin-section-cell full">
                            <p className="admin-section-small-headline">Images / Videos</p>
                            <div className="admin-media-wrap product-media-model">
                                <MediaUploader  
                                    imageOnly={false}
                                    videoOnly={false}
                                    forceCrop={false}
                                    allowGallery={true}
                                    mediaItems={mediaItems}
                                    saveMediaItems={handleMediaUpdates} 
                                    requireAltText={false}
                                    allowSorting={true}
                                    sortInTable="Typicallayout"
                                />
                            </div>
                        </div>
                    </div> 


                    <div className="admin-section-row">
                        <div className="admin-section-cell full">
                            <p className="admin-section-small-headline">Products*</p>                            
                            <div className={
                                'admin-section-cell wide' +
                                (validation.products === false
                                    ? ' input-validation-error'
                                    : '')
                            }
                            >
                                {validation.products === false ?                               
                                    <p className="validation-error-message">Product is required</p>
                                    :
                                    <div className="admin-section">
                                        {sumOfQuantities} Total Products
                                    </div>
                                }
                            </div>                            

                            <div>
                                <button
                                    className="admin-floating-button"
                                    onClick={() => {
                                        localStorage.setItem('typicallayout', true);
                                        if (sumOfQuantities > 0) {
                                            navigate('/my-project');
                                        } else {
                                            navigate('/products');
                                        }
                                    }}
                                >
                                    Add/Update Products
                                </button>

                            </div>
                        </div>
                    </div>
                </div>

                <div className="admin-floating-button-wrap">
                    <button
                        className="admin-floating-button"
                        onClick={() => {
                            navigate('/admin/districts/edit/' + districtId + '/layouts');
                            localStorage.removeItem("editTypicalLayout");
                            localStorage.removeItem("typicallayout");
                        }}
                    >
                        Cancel
                    </button>
                    <button
                        className="admin-floating-button power"
                        onClick={handleTypicalLayoutSave}
                    >
                        Save Changes
                    </button>
                </div>
            </div>
        </>
    );

}

